import { http, authHeader } from "@/http-commons";

class VentaPagoDataService {

    store(ventaPago){
        return http.post("/api/v1/ventaPago/store", ventaPago, authHeader());
    }
    table(params){
        return http.get("/api/v1/ventaPago/table", {params, headers: authHeader()});
    }
    findByVentaPago(ventaPagoId){
        return http.get(`/api/v1/ventaPago/findByVentaPago/${ventaPagoId}`, {headers: authHeader()});
    }
    downloadExcelPago(params){
        return http.get("/api/v1/ventaPago/downloadPagos", { params, headers: authHeader(), responseType: 'blob' });
    }

}

export default new VentaPagoDataService();