<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card class="elevation-1">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="handleModal">
            <v-icon>fas fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>Generar reporte Excel {{ clientName  }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
              <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="downloadExcelPago">Descargar Excel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import VentaPagoDataService from '@/service/venta/pago/VentaPagoDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

  export default {
    name: "ExcelExporterPago",
    props: {
      dialog: { type: Boolean, default: false },
      handleModal: { type: Function },
      client: { type: Object, required: false, default: () => {} },
    },
    components: { SingleCalendar },
    data() {
      return {
        startDate: getToday().toFormat('yyyy-MM-dd'),
        endDate: getToday().toFormat('yyyy-MM-dd'),
      }
    },
    methods: {
      downloadExcelPago() {
        const startDate = getDateLocaleNatural(this.startDate);
        const endDate = getDateLocaleNatural(this.endDate);
        VentaPagoDataService.downloadExcelPago({ startDate, endDate, clientId: this.clientId }).then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          if( startDate && endDate ) {
            link.setAttribute('download', `Historial_de_pagos_${startDate} - ${endDate} - ${this.clientName}.xlsx`);
          } else {
            link.setAttribute('download', `Historial_de_pagos_${this.clientName}.xlsx`);
          }
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).catch(err => {
          this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err.response.data, showConfirmButton: false, timer: 3500 });
        });
      }
    },
    computed: {
      clientName() {
        if( this.client ) {
          return this.client.nombreComercial;
        }
        return '';
      },
      clientId() {
        return this.client === undefined ? null : this.client.idCliente;
      }
    }
  }
  </script>