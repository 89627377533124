<template>
    <v-menu offset-y :close-on-content-click="closeOnContentClick">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" dark v-bind="attrs" v-on="on" class="mt-5 ml-2" small>
                <v-icon>fas fa-columns</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-card class="mx-auto elevation-4" max-width="720">
                <v-card-text>
                    <v-text-field v-model="searchHeader" append-icon="fas fa-search" label="Buscar columnas..." single-line hide-details dense>
                    </v-text-field>
                    <v-checkbox v-model="selectAll" :label="getMenuName" @change="selectAllHeaders">
                    </v-checkbox>
                </v-card-text>
                <v-divider></v-divider>
                <v-virtual-scroll :items="filteredHeaders" :item-height="30" height="420">
                    <template v-slot:default="{ item }" >
                        <v-list-item>
                            <v-list-item-action>
                                <v-checkbox v-model="item.show" :disabled="item.value == 'actions' ? true : false"/>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-card>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "Columns",
    props: {
        headers: { type: Array, default: () => [] }
    },
    created() {
        this.headersMenu = this.headers
    },
    data() {
        return {
            headersMenu: this.headers,
            searchHeader: '',
            selectAll: false,
            closeOnContentClick: false,
        }
    },
    methods: {
        selectAllHeaders() {
            this.headers.forEach(header => {
                if(header.value == 'actions'){
                    header.show = true
                } else {
                    header.show = this.selectAll
                }
            })
        },
    },
    computed: {
        filteredHeaders() {
            return this.headers.filter(header => header.text.toLowerCase().includes(this.searchHeader.toLowerCase()));
        },
        getMenuName(){
            return this.selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'
        }
    }
}
</script>