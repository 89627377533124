<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsCliente" v-slot="{ invalid, validated}">
                    <v-form name="form" @submit.prevent="store">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar cliente
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Nombre comercial del cliente*" prepend-icon="fas fa-user-tag" required
                                        v-model="cliente.nombreComercial" :counter="100" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Razón social*" prepend-icon="fas fa-user-tag" required
                                        v-model="cliente.razonSocial" :counter="100" :error-messages="errors" :success="valid"
                                        hint="Escribir la razon social del cliente">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-checkbox label="Cuenta con RFC ?" v-model="cliente.tieneRfc" color="primary">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                    <ValidationProvider rules="required|min:12|max:13" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field type="text" label="RFC*" prepend-icon="fas fa-qrcode" required
                                        v-model="cliente.rfc" :counter="13" :error-messages="errors" :success="valid" :readonly="!cliente.tieneRfc"
                                        :hint="!cliente.tieneRfc ? 'Este es un RFC generico' : 'Escriba el RFC del cliente'">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                               
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min:10|max:13" v-slot="{ errors, valid}">
                                        <v-text-field type="tel" label="Celular*" prepend-icon="fas fa-mobile" required
                                        v-model="cliente.celular" :counter="13" hint="Numero de celular del cliente."
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <ValidationProvider rules="numeric|min:10|max:13" v-slot="{ errors}" :immediate="true">
                                        <v-text-field type="tel" label="Telefono" prepend-icon="fas fa-phone"
                                        v-model="cliente.telefono" :counter="13" hint="Campo opcional"
                                        :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="email" v-slot="{ errors }" :immediate="true">
                                        <v-text-field label="Correo" prepend-icon="fas fa-envelope"
                                        v-model="cliente.email" counter :error-messages="errors" hint="Escribir el correo del cliente">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                
                                <v-col cols="6" sm="6" md="5">
                                    <v-text-field label="Dirección" prepend-icon="fas fa-map-marker-alt"
                                    v-model="cliente.direccion" counter hint="Escribir la dirección del cliente">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="mt-n5">
                                    <v-checkbox v-model="cliente.hasCredito" label="¿Tiene credito?" color="success" hide-details></v-checkbox>
                                </v-col>
                                <template v-if="cliente.hasCredito">
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Dias de credito" type="number" prepend-icon="fas fa-calendar-alt"
                                            v-model="cliente.diasCredito" min="1"
                                            hint="Escribir los dias de credito del cliente" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Numero de documento" type="number" prepend-icon="fas fa-file-invoice"
                                            v-model="cliente.documentoCredito" hint="Cuantos documentos / ventas tiene el cliente para el credito"
                                            :error-messages="errors" :success="valid" min="1">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|decimal|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Limite de credito" type="number" prepend-icon="fas fa-dollar-sign"
                                            v-model="cliente.limiteCredito" min="1"
                                            hint="Escribir el limite de credito del cliente" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </template>
                                <v-col cols="6" sm="6" md="6">
                                    <v-textarea class="mx-2" label="Dato adicional" rows="1" hint="Informacion extra del cliente.."
                                    prepend-icon="fas fa-comment" persistent-hint v-model="cliente.descripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-autocomplete :items="listaPrecio" item-text="clave" item-value="id" clearable
                                        v-model="cliente.listaPrecioId" label="Lista de precio para venta*" required prepend-icon="fas fa-list-alt"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <small>* Indica son campos requeridos</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import UbicacionDataService from '@/service/catalogo/ubicacion/ubicacionDataService';
import ClienteDataService from '@/service/cliente/clienteDataService';
export default {
    name: "NuevoCliente",
    props: {
        dialog: { default: false, type: Boolean },
        handleModal: { type: Function },
        initialize: { type: Function },
        listaPrecio: { type: Array }
    },
    data () {
        return {
            cliente: {
                nombreComercial: '',
                razonSocial: '',
                direccion: '',
                tieneRfc: false,
                rfc: 'XAXX010101000',
                telefono: '',
                celular: '',
                email: '',
                listaPrecioId: null,
                eliminado: false,
                // idPais: null,
                // idEstado: null,
                // idMunicipio: null,
                descripcion: '',
                hasCredito: false,
                diasCredito: 0,
                documentoCredito: 0,
                limiteCredito: 0
            },

        }
    },
    methods: {
        async getPaises(){
            this.paises = await UbicacionDataService.getAllPais().then( rsp => rsp.data.rows );
        },
        async getEstados( id ){
            this.estados = await UbicacionDataService.getEstadoByPais( id ).then( rsp => rsp.data.rows );
        },
        async getMunicipios( id ){
            this.municipios = await UbicacionDataService.getMunicipioByEstado( id ).then( rsp => rsp.data.rows );
        },
        store(){
            this.$refs.obsCliente.validate().then( isValid => {
                if( !isValid ){
                    this.$swal.fire({ icon: 'error', title: 'Error al crear el cliente', text: 'Hay campos por rellenar , favor de revisar',
                    showConfirmButton: false, timer: 2000 })
                }
                ClienteDataService.store( this.cliente ).then(_ => {
                    this.$refs.obsCliente.reset()
                    this.initialize();
                    this.handleModal();
                    this.$swal.fire({ icon: 'success', title: 'Cliente registrado', text: 'Cliente creado exitosamente', showConfirmButton: false, timer: 2000 })
                }).catch( err => {
                    this.$swal.fire({ icon: 'error', title: 'Error al registrar el cliente', html: err.response.data, showConfirmButton: false, timer: 2000 })
                });
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Error al validar el formulario de cliente', text: err.message, showConfirmButton: false, timer: 2000 })
            })
        }
    },
    watch: {
        idPais: function( val ) {
            if( val != null ){ this.getEstados(val) }
        },
        idEstado: function( val ){
            if( val != null ){ this.getMunicipios(val) }
        },
        'cliente.tieneRfc': function( val ){
            this.cliente.rfc = ''
            if( !val ){
                this.cliente.rfc = 'XAXX010101000'
            }
        }

    },
}
</script>