<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" dark>
            <v-card class="elevation-1">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo cliente
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar cliente" single-inline hide-details @keydown.enter="searchClient">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="clientes" sort-by="id" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalClients">
                    <template v-slot:[`item.actions`]="{ item }">
                        <ActionsCliente :item="item"  :init-table-client.sync="initTableClient"
                        :edit-client="editClient" :open-excel="openExcelExporter"/>
                    </template>

                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip :color="getColor(item.eliminado)" label text-color="white" small dark>
                            <v-icon left color="white" small>fas fa-tag</v-icon>
                            {{ item.eliminado ? 'Inactivo' : 'Activo' }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <NuevoCliente :dialog.sync="dialog" :initialize.sync="initTableClient" :listaPrecio="listaPrecio" :handleModal="handleModal" v-if="dialog"/>
        <EditarCliente :dialogEdit.sync="dialogEdit" :data="dataCliente" :initialize.sync="initTableClient" :listaPrecio="listaPrecio" :handleModal="handleModalEdit" v-if="dialogEdit"/>
        <ExcelExporterPago :dialog.sync="dialogExcel" :handleModal="handleModalExcel" :client="dataCliente" v-if="dialogExcel"/>
    </v-row>
</template>

<script>
import ClienteDataService from '@/service/cliente/clienteDataService'
import ListaPrecioDataService from '@/service/producto/ListaPrecioDataService'
import NuevoCliente from './NuevoCliente.vue';
import EditarCliente from './EditCliente.vue';
import { getParamsPagination } from '@/utils/pagination';
import ActionsCliente from './ActionsCliente.vue';
import ExcelExporterPago from '@/views/venta/pago/ExcelExporterPago.vue';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: 'Cliente',
    components: { NuevoCliente, EditarCliente, ActionsCliente, ExcelExporterPago, Columns },
    created() {
        this.initTableClient();
        this.initListaPrecios();
    },
    data() {
        return {
            listaPrecio: [],
            clientes: [],
            options: {},
            totalClients: 0,
            search: "",
            loading: false,
            showClientDeleted: false,
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'IdCliente', value: 'idCliente', align: ' d-none', sortable: false, visible: false, groupable: false, show: false },
                { text: 'Nombre Comercial', value: 'nombreComercial', groupable: false, show: true },
                { text: 'Razon social', value: 'razonSocial', groupable: false, show: true },
                { text: 'Direccion', value: 'direccion', groupable: false, show: true },
                { text: 'RFC', value: 'rfc', groupable: false, show: true },
                { text: 'Telefono', value: 'telefono', groupable: false, show: true },
                { text: 'Celular', value: 'celular', groupable: false, show: true },
                { text: 'Descripcion', value: 'descripcion', groupable: false, show: true },
                { text: 'Modifico (g)', value: 'modifico', align: ' d-none', groupable: false, show: false },
                { text: 'Estatus (g)', value: 'eliminado', groupable: false, show: true },
            ],
            dialog: false,
            dialogEdit: false,
            idCliente: null,
            dataCliente: [],
            dialogExcel: false
        }
    },
    methods: {
        searchClient() {
            this.options.page = 1;
            this.initTableClient(this.options);
        },
        initTableClient( options = {} ) {
            this.loading = true
            const params = getParamsPagination({ options, search: this.search,
                params: { deleted: this.showClientDeleted }
            });
            ClienteDataService.lstClientTable(params).then(response => {
                const { rows, totalElements } = response.data;
                this.clientes = rows;
                this.totalClients = totalElements;
            }).catch(err => {
                this.$swal.fire({ title: 'Error', html: err.response.data, icon: 'error', showConfirmButton: false, timer: 1500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        editClient(item) {
            this.dataCliente = item;
            this.dialogEdit = true;
        },
        openExcelExporter(item) {
            this.dataCliente = item;
            this.dialogExcel = true;
        },
        getColor(status) { return (status) ? 'red' : 'green'},
        handleModal() { this.dialog = !this.dialog; },
        handleModalEdit() { this.dialogEdit = !this.dialogEdit; },
        handleModalExcel() { this.dialogExcel = !this.dialogExcel; },
        async initListaPrecios(){
            this.listaPrecio = await ListaPrecioDataService.findAll().then( rsp => rsp.data );
        },

    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableClient(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>