import { http, authHeader } from '@/http-commons';

class ClienteDataService {

    store( cliente ){
        return http.post("api/v1/cliente/store", cliente, { headers : authHeader() });
    }
    lstClientTable( params ){
        return http.get('api/v1/cliente/table', { params, headers: authHeader()} );
    }
    show( idCliente ){
        return http.get(`api/v1/cliente/show/${idCliente}`, { headers : authHeader() });
    }
    update( cliente ){
        return http.post(`api/v1/cliente/update/${cliente.idCliente}`, cliente , { headers : authHeader() });
    }
    delete ( idCliente ){
        return http.delete(`api/v1/cliente/delete/${idCliente}`, { headers : authHeader() });
    }
    restore ( idCliente ){
        return http.get(`api/v1/cliente/restore/${idCliente}`, { headers : authHeader() });
    }
    getAll(){
        return http.get('api/v1/cliente/getAll', { headers: authHeader() });
    }
}
export default new ClienteDataService();