<template>
    <v-row justify="center">
        <v-dialog v-model="dialogEdit" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refFormClientEdit" v-slot="{ invalid, validated}" :immediate="true">
                    <v-form name="form" @submit.prevent="update">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar datos del Cliente</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar cambios</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Nombre comercial del cliente*" prepend-icon="fas fa-user-tag"
                                        required clearable v-model="cliente.nombreComercial"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field label="Razon social*" prepend-icon="fas fa-user-tag" 
                                        hint="Razon social del cliente" required clearable v-model="cliente.razonSocial"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-checkbox label="Cuenta con RFC ?" v-model="cliente.tieneRfc" color="primary">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                    <ValidationProvider rules="required|min:12|max:13" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field label="RFC*" hint="RFC del cliente" prepend-icon="fas fa-qrcode"
                                        required v-model="cliente.rfc" :error-messages="errors" :success="valid" :disabled="!cliente.tieneRfc">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min:10|max:13" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field label="Celular*" prepend-icon="fas fa-mobile" required
                                            type="tel"  v-model="cliente.celular" :counter="13" hint="Numero de celular del cliente."
                                            :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <ValidationProvider rules="numeric|min:10|max:13" v-slot="{ errors}" :immediate="true">
                                        <v-text-field label="Telefono" prepend-icon="fas fa-phone" v-model="cliente.telefono"
                                        :counter="13" hint="Escribir el telefono del cliente" type="tel"
                                        :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="email" v-slot="{ errors}" :immediate="true">
                                        <v-text-field label="Correo" prepend-icon="fas fa-envelope"
                                            v-model="cliente.email" :counter="100" hint="Escribir el correo del cliente"
                                            :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="6" sm="6" md="5">
                                    <v-text-field label="Dirección" prepend-icon="fas fa-map-marker-alt" required
                                        v-model="cliente.direccion" :counter="100" hint="Escribir la dirección del cliente">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="mt-n5">
                                    <v-checkbox v-model="cliente.hasCredito" label="¿Tiene credito?" color="success" hide-details></v-checkbox>
                                </v-col>
                                <template v-if="cliente.hasCredito">
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Dias de credito" type="number" prepend-icon="fas fa-calendar-alt"
                                            v-model="cliente.diasCredito" min="1"
                                            hint="Escribir los dias de credito del cliente" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Numero de documento" type="number" prepend-icon="fas fa-file-invoice"
                                            v-model="cliente.documentoCredito" hint="Cuantos documentos / ventas tiene el cliente para el credito"
                                            :error-messages="errors" :success="valid" min="1">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|decimal|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Limite de credito" type="number" prepend-icon="fas fa-dollar-sign"
                                            v-model="cliente.limiteCredito" min="1"
                                            hint="Escribir el limite de credito del cliente" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </template>
                                <v-col cols="6" sm="6" md="7">
                                    <v-textarea class="mx-2" label="Descripcion adicional" rows="1" hint="Informacion adicional del cliente.."
                                    prepend-icon="fas fa-comment" persistent-hint clearable v-model="cliente.descripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="listaPrecio" item-text="clave" item-value="id" clearable
                                        v-model="cliente.listaPrecioId" label="Lista de precio para venta*" required prepend-icon="fas fa-list-alt"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <small>* Indica son campos requeridos</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ClienteDataService from '@/service/cliente/clienteDataService';
export default {
    name: "EditCliente",
    props: {
        dialogEdit: { default: false, type: Boolean }, 
        handleModal: { type: Function },
        data: { type: Object },
        listaPrecio: { type: Array },
        initialize: { type: Function }
    },
    data(){
        return {
            cliente: this.data
        }
    },
    methods: {
        update(){
            this.$refs.refFormClientEdit.validate().then( isValid => {
                if(!isValid){
                    this.$swal.fire({ icon: 'error', title: 'Error', text: 'Favor de completar los campos requeridos' });
                }
                ClienteDataService.update( this.cliente ).then( _ => {
                    this.initialize();
                    this.handleModal();
                    this.$swal.fire({ icon: 'success', title: 'Cliente actualizado correctamente', showConfirmButton: false, timer: 1500 });
                }).catch( err => {
                    this.$swal.fire({ icon: 'error', title: 'Error al actualizar el cliente', html: err.response.data, showConfirmButton: false, timer: 1500 });
                });
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Error al validar los campos', text: err.message, showConfirmButton: false, timer: 1500 });
            })
        }
    },
    watch: {
        'dataCliente.tieneRfc': function( val ){
            if( !val ){
                this.cliente.rfc = 'XAXX010101000'
            }
        }
    }
}
</script>