import { http, authHeader } from '@/http-commons';

class UbicacionDataService {
    getAllPais(){
        return http.get('api/v1/ubicacion/jsonPaises', { headers : authHeader() });
    }
    getEstadoByIdPais( id ){
        return http.get(`api/v1/ubicacion/jsonEstados/${id}`, {headers : authHeader() });
    }
    getMunicipioByIdEstado( id ){
        return http.get(`api/v1/ubicacion/jsonMunicipios/${id}`, {headers : authHeader() });
    }
}
export default new UbicacionDataService();