import { DateTime } from "luxon"

const parseDateNatural = (date) => {
    if(!date) return null
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}

const parseDate = (date) => {
    if(!date) return null
    const [day, month, year] = date.split('/')
    return `${year}-${month}-${day}`
}

const getDateLocaleNatural = (date) => {
    if(!date) return null
    const day = DateTime.fromFormat(date, 'yyyy-MM-dd');
    return day.setLocale('es-mx').toFormat('dd-MM-yyyy');
}
const getToday = () => {
    return DateTime.now();
}

const getMondayOfCurrentWeek = () => {
    return getToday().startOf('week');
}

const getFirstDayOfCurrentMonth = () => {
    return getToday().startOf('month');
}

const getFullDateTodayNatural = () => {
    return getToday().setLocale('es-mx').toFormat('dd MMMM yyyy');
}

const getHourNowNatural = () => {
    return getToday().setLocale('es-mx').toFormat('h:mm a');
}


const getFirstDayOfWeek = () => {
    const today = getToday();
    return today.startOf('week');
}

const getLastDayOfWeek = () => {
    const today = getToday();
    return today.endOf('week');
}

const getWeeklyPeriod = () => {
    return `${getFirstDayOfWeek().toFormat('dd/MM/yyyy')} - ${getLastDayOfWeek().toFormat('dd/MM/yyyy')}`;
}

export {
    parseDateNatural, parseDate, getDateLocaleNatural, getToday, getMondayOfCurrentWeek, getFirstDayOfCurrentMonth,
    getFullDateTodayNatural, getHourNowNatural, getWeeklyPeriod, getFirstDayOfWeek, getLastDayOfWeek
}