import { http, authHeader } from '@/http-commons';

export default  new class ListaPrecioDataService {
    
    store( listPrice ){
        return http.post('api/v1/listaPrecio/store', listPrice , { headers : authHeader() } );
    }
    
    update( listPrice ){
        return http.post(`api/v1/listaPrecio/update/${listPrice.id}`, listPrice, { headers : authHeader() } );
    }

    show( listaPrecioId ){
        return http.get(`api/v1/listaPrecio/show/${listaPrecioId}`, { headers : authHeader() } );
    }

    cancel( listaPrecioId ){
        return http.delete(`api/v1/listaPrecio/cancel/${listaPrecioId}`, { headers : authHeader() } );
    }
    deleteDb( listaPrecioId ){
        return http.delete(`api/v1/listaPrecio/deleteDb/${listaPrecioId}`, { headers : authHeader() } );
    }

    activate( listaPrecioId ){
        return http.post(`api/v1/listaPrecio/activate/${listaPrecioId}`, { headers : authHeader() } );
    }

    findAll(){
        return http.get('api/v1/listaPrecio/findAllListaPrecio', { headers : authHeader() } );
    }

    listTable( params ){
        return http.get('api/v1/listaPrecio/table', { params, headers : authHeader() } );
    }
}