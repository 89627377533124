<template>
     <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text">
                <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item link v-if="!item.eliminado" @click="editClient(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-edit</v-icon> Editar cliente
                </v-list-item-title>
            </v-list-item>
            
            <v-list-item link v-if="!item.eliminado" :to="'contactos/' + item.idCliente">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-address-book</v-icon> Contactos
                    <!-- <v-btn icon :to="'contactos/' + item.idCliente" small tile class="mr-n1">
                        <v-icon small class="mr-3">fas fa-address-book</v-icon>
                    </v-btn> Contactos -->
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!item.eliminado" :to="'compras/' + item.idCliente">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-file-invoice</v-icon> Compras
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!item.eliminado" @click="openExcel(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-file-export</v-icon> Exportar pagos credito
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!item.eliminado && isAdmin" @click="deleteClient(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2" >fas fa-trash-alt</v-icon> Borrar cliente
                </v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="item.eliminado && isAdmin" @click="restoreClient(item)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-undo</v-icon> Restaurar cliente
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import ClienteDataService from '@/service/cliente/clienteDataService';
import UsuarioDataService from '@/service/usuario/usuarioDataService';

export default {
    name: "ActionsCliente",
    props: {
        item: { type: Object },
        editClient: { type: Function },
        openExcel: { type: Function },
        initTableClient: { type: Function }
    },
    methods: {

        deleteClient(item) {
            this.$swal({
                title: 'Estas seguro?',
                text: "No se podra revertir la decision!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(result => {
                if (result.isConfirmed) {
                    ClienteDataService.delete(item.idCliente).then(_ => {
                        this.initTableClient();
                        this.$swal.fire({ title: 'Eliminado!', text: 'El cliente ha sido eliminado', icon: 'success', showConfirmButton: false, timer: 1500 });
                    }).catch(err => {
                        this.$swal.fire({ title: 'Error', html: err.response.data, icon: 'error', showConfirmButton: false, timer: 1500 });
                    });
                }
            });
        },
        restoreClient(item) {
            this.$swal.fire({
                title: "Reactivar cliente?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#d33"
            }).then(result => {
                if (result.isConfirmed) {
                    ClienteDataService.restore(item.idCliente).then(_ => {
                        this.initTableClient();
                        this.$swal.fire({ title: 'Reactivado!', text: 'El cliente ha sido reactivado', icon: 'success', showConfirmButton: false, timer: 2500 });
                    })
                }
            }).catch(error => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', html: error.response.data, showConfirmButton: false, timer: 2200 })
            });
        },
    },
    computed: {
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
    }
}
</script>